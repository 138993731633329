<template>
<el-dialog id="create-new-opportunity" :visible.sync="dialogVisible" width="800" :show-close="false">
    <el-card>
        <DialogHeader title="Closed - Lost" @close="$emit('cancel')"></DialogHeader>
        <div class="form" v-loading="formLoading">
            <div class="select-form">
                <el-form :model="formData" :rules="rules" ref="newRentalForm">
                    <el-form-item prop="lost_reason">
                        <el-select v-model="formData.lost_reason" placeholder="Select a Lost Reason">
                            <el-option v-for="item in lostReasons" :key="item.id" :label="item.title" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="note-section text-left">
                <h4>Add a note to the contact record</h4>
                <p>Including details here might help you later.</p>
                <textarea v-model="formData.note"></textarea>
            </div>
            <div class="optional-section text-left">
                <div class="switch d-flex align-center">
                    <h4>Return Lead to Pipeline (Optional)</h4>
                    <el-switch v-model="returnToPipeline"></el-switch>
                </div>
                <div class="p">
                    This Lead will return to your Pipeline at a future date
                </div>
                <div class="p">
                    (example: set a date when you want to reach out about listings for
                    next year.)
                </div>
                <el-date-picker v-model="formData.future_date" class="date-picker" placeholder="MM/DD/YYYY" format="MM-dd-yyyy">
                </el-date-picker>
            </div>
        </div>
        <div class="dialog-footer d-flex justify-flex-end">
            <DialogCancelButton @cancel="$emit('cancel')"></DialogCancelButton>
            <DialogCLoseLeadButton @click="handleCloseLostLead"></DialogCLoseLeadButton>
        </div>
    </el-card>
</el-dialog>
</template>

<script>
import dayjs from "dayjs";
import {
    apiUpdateRentalOpportunity
} from "../../../API/api.js";
import {
    mapState
} from "vuex";

export default {
    components: {
        DialogCancelButton: () => import("../../Buttons/DialogCancel.vue"),
        DialogCLoseLeadButton: () =>
            import("../../Buttons/DialogCreateCloseLead.vue"),
        DialogHeader: () => import("../../Dialog/DialogHeader.vue"),
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false,
        },
        leaseId: {
            type: Number,
        },
    },
    data() {
        return {
            dialogVisible: this.showDialog,
            formLoading: false,
            formData: {
                lost_reason: '',
                note: "",
                future_date: null,
            },
            returnToPipeline: false,
            rules: {
                lost_reason: [{
                    required: true,
                    message: 'Please select Lost Reason',
                    trigger: 'change'
                }],
            }
        };
    },
    computed: {
        ...mapState(["lostReasons"]),
    },
    watch: {
        dialogVisible(nv) {
            if (!nv) {
                this.$emit("update:showDialog", false);
            }
        },
    },
    created() {
        this.$store.dispatch("getLostReasons");
    },
    methods: {
        async handleCloseLostLead() {
            this.$refs['newRentalForm'].validate((valid) => {
                if (valid) {
                    const {
                        future_date,
                        lost_reason,
                        note
                    } = this.formData;
                    if (lost_reason === 4 && !note) {
                        return;
                    }
                    const payload = {
                        status: "closed_lost",
                        future_date: future_date ?
                            dayjs(future_date).format("YYYY-MM-DD") : null,
                        lost_reason,
                        note,
                    };
                    try {
                        this.formLoading = true;
                        const response = apiUpdateRentalOpportunity(
                            this.leaseId,
                            payload
                        );
                        const {
                            status
                        } = response;
                        const statusText = status
                            .split("_")
                            .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
                            .join(" ");
                        this.$message.success(`Status changed to ${statusText}`);
                        this.$emit('success', {
                            id: response.id,
                            note
                        })
                        this.dialogVisible = false;
                    } catch (error) {
                        this.$emit('cancel');
                        console.log("error: ", error);
                        if (error && error.message) {
                            this.$message.error(error.message);
                        }
                    } finally {
                        this.formLoading = false;
                    }
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
    margin-top: 40px;
}

textarea {
    border: 1px solid #767676;
    width: 95%;
    border-radius: 5px;
    height: 100px;
    font-size: 18px;
    padding: 15px;
    margin-bottom: 40px;
}

/deep/ .el-select {
    width: 100% !important;
    margin-top: 20px !important;
}

/deep/ .el-form-item__content {
    margin: 0 !important;
}

.select-form {
    /deep/ .el-input__inner {
        border-style: none !important;
        border-radius: 5px !important;
        border: 1px solid #767676 !important;
        font-size: 16px !important;
        padding: 26px !important;
        display: flex;
    }
}

/deep/ .el-dialog__body {
    min-height: 50vh;
    max-height: 80vh !important;
    overflow: auto;
    padding: 0px 20px 30px 20px;
}

/deep/ .el-dialog__header {
    padding: 0px;
}

/deep/ .el-card {
    box-shadow: none !important;
    border: none !important;
}

/deep/ .el-form-item {
    width: 100% !important;
}

/deep/ .el-dialog {
    width: 783px !important;
    border-radius: 4px !important;
    margin-top: 10vh !important;

    .note-section {
        margin-bottom: 20px;
        font-family: "Manrope";
        font-style: normal;

        h4 {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #1c4a5e;
        }

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #43555a;
        }
    }

    .optional-section {
        font-family: "Manrope";
        font-style: normal;

        .switch {
            max-height: 30px;

            h4 {
                margin-right: 30px;
            }
        }

        h4 {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #1c4a5e;
        }

        .p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #43555a;
        }

        .date-picker {
            width: 75%;
        }
    }
}
</style>
